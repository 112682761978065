/* Widget: Player Featured */
.alc-widget-player-featured {

}

.alc-player-featured {

}

	.alc-player-featured__primary {

	}
		.alc-player-featured__primary-body {
			min-height: 150px;
			overflow: hidden;
			position: relative;
			isolation: isolate;
			border-block-end: 1px solid $card-border-color;
		}
			// Player Image
			.alc-player-featured__primary-player-img {
				display: block;
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-58%);
			}
			// Player Background
			.alc-player-featured__primary-player-bg {
				display: block;
				position: absolute;
				z-index: -1;
				inset-inline-start: 0;
				inset-block-start: 0;
				inset-block-end: 0;
				width: 70%;
				background-image: url("../images/hockey/samples/player-featured-team-logo.png");
				background-position: -50% 50%;
				background-repeat: no-repeat;
				background-size: 100% cover;
				clip-path: polygon(0 0, 100% 0, calc(100% - 56px) 100%, 0% 100%);
			}
			.alc-player-featured__primary-player-aside {
				position: absolute;
				inset-inline-end: $card-horizontal-padding;
				inset-block-start: 14px;
				display: flex;
				flex-direction: column;
				justify-content: end;
				align-items: flex-end;

				.alc-circle-progress {
					margin-block-end: 12px;
				}
			}

				.alc-player-awards-slider-container {
					position: relative;

					.swiper-button-prev {
						margin: 0;
						inset-inline-start: 40px;
						inset-block-start: -2px;
						width: 10px;
						height: 10px;
						line-height: 10px;

						&.swiper-button--fa::before {
							content: "\f077";
							font-size: 10px;
						}
					}
					.swiper-button-next {
						margin: 0;
						inset-inline-start: 40px;
						inset-inline-end: auto;
						inset-block-start: 10px;
						width: 10px;
						height: 10px;
						line-height: 10px;

						&.swiper-button--fa::before {
							content: "\f078";
							font-size: 10px;
						}
					}
				}
				.alc-player-awards-slider {
					width: 80px;
					height: 70px;
					text-align: end;
				}
					.alc-player-awards-slider__award-icon {
						margin-bottom: 7px;
					}
					.alc-player-awards-slider__label {
						font-size: 8px;
						line-height: 1.2em;
						text-transform: uppercase;
						margin-bottom: .4em;
					}
					.alc-player-awards-slider__title {
						font-size: 10px;
						line-height: 1em;
						color: $headings-color;
						font-weight: bold;
						padding-inline-start: 5px;
					}

		.alc-player-featured__primary-meta {
			text-align: center;
			position: relative;
			padding-block: $card-vertical-padding - 4px $card-vertical-padding;
			padding-inline: $card-horizontal-padding;
		}
			.alc-player-featured__primary-meta-header {
				margin-bottom: .5em;
			}
				.alc-player-featured__primary-meta__number {
					color: $color-gray;
				}
				.alc-player-featured__primary-meta__fname {

				}
				.alc-player-featured__primary-meta__lname {
					color: $color-primary;
				}

			.alc-player-featured__primary-meta-list {
				--font-size: 11px;

				padding: 0;
				margin: 0;
				display: flex;
				justify-content: center;
				font-size: var(--font-size);
				line-height: 1.2em;
			}
				.alc-player-featured__primary-meta-label {
					font-weight: normal;
					text-transform: none;
					font-size: var(--font-size);
					line-height: 1.2em;
					margin-inline-end: .3em;
					margin-block-end: 0;
				}
				.alc-player-featured__primary-meta-value {
					font-weight: bold;
					font-size: var(--font-size);
					line-height: 1.2em;
					color: $headings-color;
					margin-inline-end: .7em;
					margin-block-end: 0;
				}

			// Icon
			.alc-player-featured__primary-meta-icon {
				--icon-size: 40px;

				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				width: var(--icon-size);
				height: var(--icon-size);
				background-color: $card-bg;
				border-radius: 50%;
				inset-block-start: calc(var(--icon-size) / 2 * -1);
				inset-inline-start: calc(50% - var(--icon-size) / 2);
			}

	.alc-player-featured__secondary {
		border-block-start: 1px solid $card-border-color;
	}
		.alc-player-featured__stats {
			display: flex;
			background-color: $card-subheader-bg;
		}
			.alc-player-featured__stats-list {
				list-style: none;
				margin: 0;
				padding: 0;
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;

				& + & {
					border-inline-start: 1px solid $card-border-color;
				}
			}
				.alc-player-featured__stats-item {
					padding-block: calc($card-horizontal-padding / 1.5);
					padding-inline: $card-vertical-padding;
					display: flex;
					justify-content: space-between;
					align-items: center;

					& + & {
						border-block-start: 1px solid $card-border-color;
					}
				}
					.alc-player-featured__stats-item-meta {

					}
						.alc-player-featured__stats-item-title {
							font-size: $font-size-sm;
							line-height: 1.2em;
							color: $headings-color;
							margin: 0;
							text-transform: none;
						}
						.alc-player-featured__stats-item-desc {
							font-size: 9px;
							line-height: 1.2em;
							font-weight: normal;
						}
					.alc-player-featured__stats-item-value {
						font-size: $font-size-sm * 0.92;
						line-height: 1.2em;
						color: $headings-color;
						font-weight: bold;
						text-transform: none;
					}


// Compact
.alc-player-featured--compact {

	.alc-player-featured__primary {

	}

	// Player Image
	.alc-player-featured__primary-player-img {
		left: 0;
		transform: translateX(-7%);
	}

	// Player Background
	.alc-player-featured__primary-player-bg {
		width: 45%;
	}

	// Player Meta Info
	.alc-player-featured__primary-body-meta {
		display: flex;
		margin: 0 0 0 54%;
		padding-block: $card-vertical-padding;
		flex-direction: column;
	}
		.alc-player-featured__primary-body-meta-header {
			position: relative;
			margin-bottom: .12em;
		}
			.alc-player-featured__primary-body-meta-number {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				font-size: 24px;
				line-height: 1em;
				transform: translateX(calc(-100% - 8px));
				color: $color-gray-2;
			}
			.alc-player-featured__primary-body-meta-fname {
				font-size: $font-size-h4;
				line-height: 1.2em;
				display: block;
			}
			.alc-player-featured__primary-body-meta-lname {
				display: block;
				font-size: 28px;
				line-height: 1em;
				color: $color-primary;
			}

		// Position
		.alc-player-featured__primary-body-meta-list {

		}
			.alc-player-featured__primary-body-meta-item {
				display: flex;
				font-size: 11px;
				line-height: 1.2em;
				text-transform: none;
				gap: 0.5em;
			}
				.alc-player-featured__primary-body-meta-label {
					font-weight: normal;
					text-transform: none;
					font-size: 11px;
					line-height: 1.2em;
					margin-bottom: 0;
				}
				.alc-player-featured__primary-body-meta-value {
					font-weight: bold;
					color: $headings-color;
					font-size: 11px;
					line-height: 1.2em;
					margin-bottom: 0;
				}

		// Stats
		.alc-player-featured__primary-body-meta-stats {
			display: flex;
			gap: 22px;
			margin-bottom: 0;
		}
			.alc-player-featured__primary-body-meta-stat-item {
				display: inline-flex;
				flex-direction: column;
				text-align: center;
			}
				.alc-player-featured__primary-body-meta-stat-value {
					font-size: 14px;
					line-height: 1.2em;
					color: $headings-color;
					font-weight: bold;
					font-family: $headings-font-family;
				}
				.alc-player-featured__primary-body-meta-stat-label {
					font-size: 10px;
					line-height: 1.2em;
					margin-bottom: 0;
				}

		// Icon
		.alc-player-featured__primary-body-meta-icon {
			display: block;
			position: absolute;
			inset-inline-end: 34px;
			inset-block-start: 0;

			&::before,
			&::after {
				content: "";
				display: block;
				position: absolute;
				width: 12px;
				height: 32px;
				background-color: #d0fffe;
				border-inline: 4px solid #6c4dee;
			}

			&::before {
				transform: translateX(-100%) skew(20deg);
				left: 50%;
			}

			&::after {
				transform: translateX(100%) skew(-20deg);
				right: 50%;
			}

			.alc-icon--medal {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1;
				top: 14px;
			}
		}

		// Team Icon
		.alc-player-featured__primary-body-team-icon {
			display: block;
			position: absolute;
			inset-inline-end: $card-horizontal-padding;
			inset-block-start: calc($card-vertical-padding / 2);
		}


}
