/* Team Schedule & Tickets */
.team-schedule {

	.team-schedule__last5 {
		text-align: start;
	}

	.team-schedule__ticket {
		.btn {
			padding-inline: 0;
			font-size: 11px;

			&.btn-link {
				color: $headings-color;

				&:hover {
					color: $link-color-hover;
				}
			}

			&.disabled {
				opacity: .5;
			}
		}
	}

	> tbody > tr > td {

		&.team-schedule__status,
		&.team-schedule__compet {
			font-weight: normal;
			color: $body-font-color;
		}
	}
}
