.btn-social-counter:not(.btn-social-counter--card) {
	border-radius: 0;
	padding-left: 65px;

	&::after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 78px;
		background-color: rgba(0,0,0,.1);
		z-index: -1;
		clip-path: polygon(24px 0%, 100% 0%, 100% 100%, 0% 100%);
	}

	.btn-social-counter__icon {
		background-color: transparent;
		font-size: 20px;
	}

	.btn-social-counter__title {
		font-size: 10px;

		@media (min-width: 992px) {
			font-size: 12px;
		}

		@media (min-width: 992px) and (max-width: 1199px) {
			font-size: 10px;
		}
	}

	.btn-social-counter__count {
		@media (max-width: 991px) {
			font-size: 9px;
		}
	}

	.btn-social-counter__add-icon {
		border-color: transparent;
		right: 18px;

		&::before {
			width: 11px;
			height: 3px;
		}

		&::after {
			width: 3px;
			height: 11px;
		}
	}
}


/* Social Buttons Styles */
@each $color, $value in $social-links {
	.btn-social-counter--#{$color}:not(.btn-social-counter--card) {
		&::after {
			background-color: darken($value, 5%);
		}
	}
}
