/* Widget: Event Box Score */
.alc-event-box-score {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"score score"
		"scoreboard scoreboard"
		"stats stats"
		"leaders leaders"
		"recap recap";

	@media (min-width: 768px) {
		grid-template-areas:
			"score score"
			"scoreboard scoreboard"
			"stats stats"
			"leaders recap";
	}
}
	// common styles for sections inside the Event Box Score
	.alc-event-box-score__section {

		&:first-child {

			> .alc-event-box-score__section-header {
				border-block-start: 0;
			}
		}
	}
		.alc-event-box-score__section-header {
			--header-font-size: #{$font-size-h6};
			--header-line-height: 1em;

			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 10px;
			background-color: $card-subheader-bg;
			font-size: var(--header-font-size);
			line-height: var(--header-line-height);
			border-block: 1px solid $card-border-color;
			padding-inline: $card-horizontal-padding;
			padding-block: 1.25em;
			text-transform: uppercase;
			font-weight: bold;
		}
			.alc-event-box-score__section-title {
				margin: 0 auto;
				font-size: var(--header-font-size);
				line-height: var(--header-line-height);
				text-transform: uppercase;
				order: 1;

				@media (max-width: 768px) {
					flex-basis: 100%;
				}
			}
			.alc-event-box-score__section-info {
				order: 0;
				color: $headings-color;

				@media (max-width: 768px) {
					flex-basis: 100%;
				}

				& + & {
					order: 2;
					color: $body-font-color;
				}
			}

		.alc-event-box-score__section-content {
			position: relative;
			padding-block: $card-vertical-padding;
			padding-inline: $card-horizontal-padding;
		}

	// Score
	.alc-event-box-score__score {
		grid-area: score;
		border-block-end: 1px solid $card-border-color;
	}
		.alc-event-box-score__score-result {
			display: flex;
			justify-content: center;
			align-items: center;

			@media (min-width: 992px) {
				padding-block: 10px;
			}
		}
			.alc-event-box-score__score-team {
				order: 0;
				display: flex;
				align-items: center;

				& + & {
					order: 2;

					.alc-event-box-score__score-team-logo {
						margin-inline-start: 0;
						margin-inline-end: 12px;
						order: 0;

						@media (min-width: 992px) {
							margin-inline-end: 24px;
						}
					}

					.alc-event-box-score__score-team-info {
						order: 1;
						text-align: start;
					}
				}
			}
				.alc-event-box-score__score-team-logo {
					margin-inline-start: 12px;
					order: 1;

					@media (max-width: 575px) {

						img {
							width: 32px;
							height: auto;
						}
					}

					@media (min-width: 992px) {
						margin-inline-start: 24px;
					}
				}
				.alc-event-box-score__score-team-info {
					order: 0;
					text-align: end;
				}
					.alc-event-box-score__score-team-meta {
						font-size: 10px;
						line-height: 1em;
						margin-block-end: .2em;
					}
					.alc-event-box-score__score-team-name {
						font-size: $font-size-h5;
						line-height: 1em;
						margin: 0;

						@media (min-width: 992px) {
							font-size: 14px;
						}
					}

			.alc-event-box-score__score-outcome {
				order: 1;
				display: flex;
				align-items: center;
				padding-inline: 8px;

				@media (min-width: 1200px) {
					padding-inline: 16px;
				}
			}
				.alc-event-box-score__score-outcome-team {
					font-size: $font-size-h3;
					line-height: 1em;
					font-weight: bold;
					font-family: $headings-font-family;
					padding-inline: 10px;
					order: 0;

					@media (min-width: 992px) {
						font-size: $font-size-h2;
					}

					@media (min-width: 1200px) {
						padding-inline: 24px;
					}

					& + & {
						order: 2;
					}
				}
				.alc-event-box-score__score-outcome-team--winner {
					color: $headings-color;
				}

				.alc-event-box-score__score-outcome-separator {
					order: 1;

					@media (max-width: 575px) {
						display: none;
					}

					@media (min-width: 576px) {
						padding-inline: 10px;
					}

					@media (min-width: 768px) {
						padding-inline: 16px;
					}

					@media (min-width: 1200px) {
						padding-inline: 30px;
					}
				}

	// Scoreboard
	.alc-event-box-score__scoreboard {
		grid-area: scoreboard;
	}
		.alc-event-box-score__scoreboard-table {
			margin: 0;

			> thead > tr > th {
				font-size: 10px;
			}

			> thead > tr > th,
			> tbody > tr > td {
				border: 1px solid $table-border-color;
				text-align: center;

				&:first-child {
					padding-inline-start: $card-horizontal-padding;
					text-align: start;
					border-inline-start: none;
				}

				&:last-child {
					border-inline-end: none;
				}
			}

			> tbody > tr > td {
				font-weight: normal;
				color: $body-font-color;

				&:last-child {
					font-weight: bold;
				}
				&.alc-total {
					color: $table-highlight;
				}
			}

			// Hightlight the winner result
			> tbody > tr {

				&.alc-team-winner {

					> td:last-child {
						color: $table-highlight;
					}
				}
			}

			> tbody > tr:last-child {

				> td {
					border-block-end: none;
				}
			}

			.alc-name {

				strong {
					color: $headings-color;
					display: inline-block;
					margin-inline-end: 1em;
				}
			}
		}

	// Statistics
	.alc-event-box-score__statistics {
		grid-area: stats;
	}
		.alc-event-box-score__statistics-grid {
			display: grid;
			gap: 17px 40px;
			grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
		}
		.alc-event-box-score__statistics-grid--row-gap-lg {
			row-gap: 20px;
		}
		.alc-event-box-score__statistics-grid--fullwidth {
			grid-template-columns: 1fr;
		}

	// Leaders
	.alc-event-box-score__leaders {
		grid-area: leaders;
		border-inline-end: 1px solid $card-border-color;

		.alc-event-box-score__section-header {
			position: relative;

			&::after {
				content: "";
				display: block;
				position: absolute;
				inset-inline-end: -1px;
				inset-block-start: 0;
				inset-block-end: 0;
				width: 1px;
				background-color: $card-subheader-bg;
			}
		}
	}

		.alc-leaders-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
			.alc-leaders-list__item {
				display: flex;
				align-items: center;
				column-gap: 10px;
				padding: $card-vertical-padding $card-horizontal-padding;
				font-size: 9px;
				line-height: 1.2em;
				flex-basis: 100%;

				& + & {
					border-block-start: 1px solid $card-border-color;
				}
			}
				.alc-leaders-list__player {
					display: flex;
					align-items: center;
					flex-grow: 1;
				}
					.alc-leaders-list__player-container {
						position: relative;
						margin-inline-end: 12px;

						.alc-icon {
							position: absolute;
							inset-block-end: 0;
							inset-inline-end: 0;
						}
						.alc-icon--medal {
							transform-origin: 100% 100%;
							transform: scale(0.57);
						}
					}
						.alc-leaders-list__player-img {
							--decor-gap: 7px;
							--decor-color: #{$color-primary};

							position: relative;
							clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
							margin-top: calc(var(--decor-gap) * -1);

							&::before {
								content: "";
								display: block;
								position: absolute;
								inset: var(--decor-gap) 0 0;
								background-color: var(--decor-color);
								z-index: -1;
							}
						}
						.alc-leaders-list__player-info {
							flex: 1;
						}
							.alc-leaders-list__player-name {
								font-size: $font-size-h5;
								line-height: 1.1em;
								margin-block-end: 0;
							}
								.alc-leaders-list__player-position {

								}
							.alc-leaders-list__player-team {

							}

				.alc-leaders-list__stat {
					text-align: center;
				}
					.alc-leaders-list__stat-value {
						font-size: 11px;
						line-height: 1.3em;
						font-weight: bold;
						color: $headings-color;
					}
					.alc-leaders-list__stat-label {

					}

	// Recap
	.alc-event-box-score__recap {
		grid-area: recap;
	}


// Box Score - Large
.alc-event-box-score__score--lg {

	// Result
	.alc-event-box-score__score-result {

		@media (min-width: 992px) {
			padding-inline: 200px;
		}
	}

	.alc-event-box-score__section-header {
		padding-block: 1.5em;
	}

	.alc-event-box-score__score-team-name {
		@media (min-width: 992px) {
			font-size: $font-size-h4;
		}
	}

	.alc-event-box-score__score-outcome-team {
		@media (min-width: 992px) {
			font-size: 38px;
		}
	}

	// Decorations
	.alc-event-box-score__score-decors {
		position: absolute;
		inset: 0;
		pointer-events: none;
	}
		.alc-event-box-score__score-decor {
			--decor-color: #{$color-primary};

			display: none;
			overflow: hidden;
			position: absolute;
			inset-inline-start: 0;
			inset-block: 0;
			background-color: var(--decor-color);
			clip-path: polygon(0 0, 79% 0, 100% 100%, 0% 100%);

			@media (min-width: 992px) {
				display: block;
				width: 206px;
			}
		}
		.alc-event-box-score__score-decor + .alc-event-box-score__score-decor {
			inset-inline-start: auto;
			inset-inline-end: 0;
			clip-path: polygon(21% 0%, 100% 0%, 100% 100%, 0% 100%);

			.alc-event-box-score__score-decor-img {
				transform: translate(-45%, -50%);
			}
		}
			.alc-event-box-score__score-decor-img {
				position: absolute;
				inset-inline-start: 50%;
				inset-block-start: 50%;
				transform: translate(-55%, -50%);
			}
}

// Scoreboard Large
.alc-event-box-score__scoreboard--lg {

	.alc-event-box-score__scoreboard-table {
		> tbody > tr > td {

			@media (min-width: 992px) {
				padding-inline: 26px;

				&.alc-name {
					width: 100%;
				}
			}
		}
	}
}


// Small (used in the Sidebar)
.alc-event-box-score--sm {
	grid-template-columns: 1fr;
	grid-template-areas:
		"score"
		"scoreboard"
		"stats"
		"leaders";

	@media (min-width: 768px) {
		grid-template-areas:
			"score"
			"scoreboard"
			"stats"
			"leaders";
	}

	.alc-event-box-score__section-header {
		justify-content: space-between;
	}

	.alc-event-box-score__score-result {
		justify-content: space-between;
		align-items: flex-start;

		@media (min-width: 992px) {
			padding-block-end: 5px;
		}
	}
		.alc-event-box-score__score-team {
			flex-direction: column;
		}

		.alc-event-box-score__score-outcome-separator {

			@media (max-width: 575px) {
				display: flex;
				padding-inline: 12px;
			}

			@media (min-width: 576px) {
				padding-inline: 8px;
			}

			@media (min-width: 768px) {
				padding-inline: 13px;
			}

			@media (min-width: 1200px) {
				padding-inline: 20px;
			}
		}

		// Outcome
		.alc-event-box-score__score-outcome {
			padding-inline: 0;

			@media (min-width: 1200px) {
				padding-inline: 0;
			}
		}
			.alc-event-box-score__score-outcome-team {
				padding-inline: 0;

				@media (min-width: 1200px) {
					padding-inline: 0;
				}
			}

		// Team
		.alc-event-box-score__score-team {
			align-items: flex-start;
		}

		.alc-event-box-score__score-team + .alc-event-box-score__score-team {
			align-items: flex-end;

			.alc-event-box-score__score-team-logo {
				margin-inline-start: 0;
				margin-inline-end: 0;

				@media (min-width: 992px) {
					margin-inline-end: 0;
				}
			}
			.alc-event-box-score__score-team-info {
				text-align: end;
			}
		}

			.alc-event-box-score__score-team-logo {
				margin-inline-start: 0;
				margin-block-end: 10px;
				order: 0;

				@media (min-width: 992px) {
					margin-inline-start: 0;
					margin-block-end: 15px;
				}
			}
			.alc-event-box-score__score-team-info {
				order: 1;
				text-align: start;
			}
				.alc-event-box-score__score-team-name {
					font-size: $font-size-h6;
					margin-block-end: .2em;

					@media (min-width: 992px) {
						font-size: $font-size-h5;
					}
				}
}
