/* Table Stats */
.alc-table-stats {

	> tbody > tr > td {

		&.alc-number,
		&.alc-position {
			font-weight: normal;
			color: $body-font-color;
		}
	}
}
