.posts__item,
.post--single {

	.post__meta--row {

		.meta__item--cats + .meta__item--author {
			margin-left: -5px;
		}

		.meta__item:not(.meta__item--cats) {
			text-transform: none;
		}

		.meta__item--author {
			font-weight: bold;
		}

		.meta__item-label {
			color: $color-gray;
		}
	}
}
