/* Products */
.alc-products {
	display: grid;
	padding: 0;
	margin: 0;
	list-style: none;
}

	// Product
	.alc-product {
		--product-padding: calc(#{$card-horizontal-padding} / 2);

		background-color: $card-bg;
		border: 1px solid $card-border-color;
		padding: var(--product-padding);
		display: grid;
		position: relative;
	}
		// Title
		.alc-product__title {
			font-size: 14px;
			line-height: 1.2em;
			margin-bottom: .75em;

			> a {
				color: $headings-color;

				&:hover {
					color: $link-color-hover;
				}
			}
		}
		// Price
		.alc-product__price {
			font-size: 12px;
			line-height: 1.2em;
			color: $color-4-darken;
			font-weight: bold;
		}
		// Category
		.alc-product__category {
			font-size: 9px;
			line-height: 1.2em;
			text-transform: uppercase;
			font-weight: normal;
			color: $color-gray;
			margin-bottom: 0.2em;
		}
		// Image
		.alc-product__img {

			img {
				width: 100%;
				object-fit: cover;
			}
		}
		// Ratings
		.alc-product__ratings {
			font-size: 7px;
			line-height: 1em;
			text-align: end;
			padding-inline-end: 10px;
			padding-block-start: 12px;
			white-space: nowrap;

			.fa,
			.fab,
			.fas {
				margin-inline: 1px;
			}
		}



// Grid
.alc-products--grid {
	grid-template-rows: auto;
	grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
	gap: 16px;

	// Product
	.alc-product {
		grid-template-rows: 40px 1fr auto auto auto;
		grid-template-columns: 50px 1fr 70px;
	}

	// Product Card includes box shadow on hover
	.alc-product--hover-shadow {

		&:hover {
			box-shadow: 0 20px 40px 0 rgba(0,0,0,.1);
			transition: box-shadow .3s ease;
		}
	}

	// Product Card includes hidden buttons by default and visible on hover
	.alc-product--hover-options {

		.alc-product__options {
			grid-area: -1 / 1 / -1 / -1;
			position: absolute;
			overflow: hidden;
			margin: 0;
			inset: auto var(--inline-margin) 0 var(--inline-margin);
			transform: translateY(100%);
			opacity: 0;
			background-color: $card-border-color;
			border: 1px solid $card-border-color;
			display: flex;
			z-index: 1;
			height: 54px;
			pointer-events: none;
		}

		// Display button on hover
		&:hover {

			.alc-product__options {
				opacity: 1;
				pointer-events: auto;
				box-shadow: 0 20px 40px 0 rgba(0,0,0,.1);
				transition: opacity .3s ease, box-shadow .3s ease;
			}
		}
	}

		.alc-product__img {
			grid-area: 1 / 1 / 3 / -1;
			margin-block-end: 17px;

			img {
				aspect-ratio: 242 / 290;
			}
		}
		.alc-product__category {
			grid-area: 3 / 1 / 4 / -1;
		}
		.alc-product__title {
			grid-area: 4 / 1 / 5 / -1;
		}
		.alc-product__price {
			grid-area: -1 / 1 / -2 / -1;
			padding-block-end: 8px;
		}
		.alc-product__add-to-cart {
			grid-area: 1 / 1 / 2 / 2;
			clip-path: polygon(0 0, 100% 0%, calc(100% - 15px) 100%, 0% 100%);
			text-align: start;
			padding-inline: 10px 0;
		}
		.alc-product__ratings {
			grid-area: 1 / -1 / 2 / -2;
		}
		.alc-product__options {
			--inline-margin: calc(var(--product-padding) * -1 - 1px);

			grid-area: -1 / 1 / -1 / -1;
			margin: var(--product-padding) var(--inline-margin) var(--inline-margin) var(--inline-margin);
			overflow: hidden;
			background-color: $card-border-color;
			border: 1px solid $card-border-color;
			display: flex;
			z-index: 1;
			height: 54px;
		}
			.alc-product__btn-option {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $color-gray-3;
				color: $color-gray;
				transform: skew(-20deg);

				&:first-child {
					margin-inline-start: -10px;
				}

				&:last-child {
					margin-inline-end: -10px;
				}

				.far,
				.fas,
				.fab {
					transform: skew(20deg);
					font-size: 15px;
				}

				&:hover {
					background-color: $card-bg;
					color: $headings-color;
				}
			}
			.alc-product__btn-option + .alc-product__btn-option {
				border-inline-start: 1px solid $card-border-color;
			}
			.alc-product__btn-option--active {
				&,
				&:hover {
					color: $color-primary;
				}
			}
}


// List
.alc-products--list {
	display: grid;
	gap: 16px;

	// Product
	.alc-product {
		display: grid;
		grid-template-columns: 242px 45px 1fr 120px 43px;
		grid-template-rows: auto auto auto 1fr auto;

		@media (max-width: 767px) {
			grid-template-columns: 1fr 80px;
			grid-template-rows: auto;
		}
	}
		.alc-product__img {
			grid-area: 1 / 1 / -1 / 2;

			@media (max-width: 767px) {
				grid-area: 1 / 1 / 2 / -1;
			}

			img {
				aspect-ratio: 242 / 290;
			}
		}
		.alc-product__category {
			grid-area: 1 / 3 / 2 / 4;
			padding-block: 20px 3px;

			@media (max-width: 767px) {
				grid-area: 2 / 1 / 3 / -1;
			}
		}
		.alc-product__title {
			grid-area: 2 / 3 / 3 / 4;
			font-size: $font-size-h3;
			line-height: 1.2em;
			margin-block-end: .4em;

			@media (max-width: 767px) {
				grid-area: 3 / 1 / 4 / -2;
			}
		}
		.alc-product__price {
			grid-area: 2 / -2 / 3 / -3;
			font-size: $font-size-h3;
			line-height: 1.2em;
			color: $color-4-darken;
			text-align: end;

			@media (max-width: 767px) {
				grid-area: 3 / -2 / 4 / -1;
			}
		}
		.alc-product__ratings {
			grid-area: 3 / 3 / 4 / 4;
			text-align: start;

			@media (max-width: 767px) {
				grid-area: 4 / 1 / 5 / -1;
			}
		}
		.alc-product__excerpt {
			grid-area: 4 / 3 / 5 / 5;
			padding-block: 24px;

			@media (max-width: 767px) {
				grid-area: 5 / 1 / 6 / -1;
			}
		}
		.alc-product__options {
			grid-area: -1 / 3 / -2 / -2;
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			padding-block-end: 18px;

			@media (max-width: 767px) {
				grid-area: 6 / 1 / 7 / -1;
			}
		}


}


// Modal Product
.modal--product {

	.modal-dialog {
		width: 80%;

		@media (min-width: 576px) {
			width: 970px;
			max-width: 80%;
		}

		@media (min-width: 992px) {
			width: 970px;
			max-width: 970px;
		}
	}
}

// Single Product
.alc-product-single {
	display: grid;
	grid-template-columns: 1.04fr 50px 1fr 50px;
	grid-template-rows: auto;

	@media (max-width: 575px) {
		grid-template-columns: 1fr 50px;
	}
}
	.alc-product-single__img,
	.alc-product-single__slider-wrapper {
		grid-area: 1 / 1 / -1 / 2;

		@media (max-width: 575px) {
			grid-area: 1 / 1 / 2 / -1;
		}
	}
	.alc-product-single__body {
		grid-area: 1 / 3 / -1 / -2;
		padding-block: 35px;

		@media (max-width: 575px) {
			grid-area: 2 / 1 / -1 / -1;
			padding-inline: 20px;
		}
	}
		.alc-product-single__category {
			font-size: 10px;
			line-height: 1.2em;
			text-transform: uppercase;
			font-weight: normal;
			color: $color-gray;
		}
		.alc-product-single__title {
			font-size: 28px;
			line-height: 1em;
			margin-bottom: .6em;

			> a {
				color: $headings-color;

				&:hover {
					color: $link-color-hover;
				}
			}
		}
		.alc-product-single__meta {
			display: flex;
			flex-wrap: wrap;
		}
		.alc-product-single__ratings {
			font-size: 7px;
			line-height: 1em;
			margin-block-end: 20px;

			.fa,
			.fab,
			.fas {
				margin-inline: 1px;
			}
		}
		.alc-product-single__price {
			font-size: 22px;
			line-height: 1em;
			color: $color-4-darken;
			font-weight: bold;
		}
		.alc-product-single__excerpt {
			padding-block: 24px;
			line-height: 26px;
		}
		.alc-product-single__meta {
			list-style: none;
			padding: 0;
			margin: 0 0 30px 0;
			display: flex;
			gap: 20px 66px;
		}
			.alc-product-single__meta-item {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
				.alc-product-single__meta-item-label {
					font-size: $font-size-sm;
					line-height: 1.2em;
					color: $color-gray;
				}
				.alc-product-single__meta-item-value {
					color: $headings-color;
					font-weight: bold;
					line-height: 1.2em;
				}
		.alc-product-single__color {

		}
			.alc-product-single__color-title {
				font-size: 12px;
				line-height: 1.2em;
				font-weight: normal;
				margin-bottom: 0.5em;
				color: $color-gray;
				text-transform: none;
			}
		.alc-product-single__actions {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			padding-block-start: 40px;
		}
			.alc-product-single__quantity-control {
				width: 100px;
			}

		// Details
		.alc-product-single__details {
			display: flex;
			flex-direction: column;
			gap: 1em;
			list-style: none;
			padding: 0;
			padding-block-start: 55px;
			margin: 0;
			font-size: $font-size-sm;
			line-height: 1.2em;
		}
			.alc-product-single__detail {

			}
				.alc-product-single__detail-label {
					font-family: $font-family-accent;
					color: $headings-color;
					font-weight: bold;
					text-transform: uppercase;
				}
				.alc-product-single__detail-value {

					a {
						color: $body-font-color;

						&:hover {
							color: $link-color-hover;
						}
					}
				}

		// Social Sharing
		.alc-product-single__sharing {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			align-items: center;
			font-size: $font-size-sm;
			line-height: 1.2em;
			padding-block-start: 28px;
		}
			.alc-product-single__sharing-title {
				font-family: $font-family-accent;
				color: $headings-color;
				font-weight: bold;
				text-transform: uppercase;
			}
			.alc-product-single__sharing-list {

				.social-links__link {
					i {
						margin-inline-end: 0;
					}
				}
			}

// Single Product - Single Page
.alc-product-single--main {
	display: block;

	.alc-product-single__category {
		padding-block-end: 5px;
	}

	.alc-product-single__header {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;
		padding-block-end: 20px;

		.alc-product-single__title {
			margin-bottom: 0;
		}

		.alc-product-single__price {
			@media (min-width: 992px) {
				font-size: 28px;
			}
		}
	}

	.alc-product-single__excerpt {
		padding-block: 7px 30px;
	}
}


// Single Product Slider
.alc-product-single__slider-wrapper {
	display: flex;
	align-items: flex-start;
	width: calc(100% - 30px);
	gap: 16px;

	@media (max-width: 991px) {
		width: 100%;
	}
}
	.alc-product-single__slider-nav {
		flex-basis: 64px;
		flex-shrink: 0;
		height: 570px;

		@media (max-width: 575px) {
			display: none;
		}
	}
		.alc-product-single-slides-nav {
			height: 100%;

			.swiper-slide {
				> img {
					cursor: pointer;
				}
			}
		}
	.alc-product-single-slides {
		flex: 1;
	}

.alc-product-single-slider {
	width: 100%;
	min-width: 0;

	.swiper-slide {
		img {
			width: 100%;
			height: auto;
		}
	}
}
