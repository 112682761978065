/* Comments
/* ------------------------- */

// Comments
.comments--alt {

	// by post author
	.comments__item {

		&.bypostauthor

		.comments__inner {
			background-color: $color-gray-3;
		}
	}

	.comment__author-avatar {
		border-radius: 0;
		margin-right: 20px;
	}

	.comment__author-name {

	}

	.comment__post-date {
		font-size: $font-size-sm;
	}

	.comment__body {
		font-size: $base-font-size;
		line-height: $base-line-height;
	}

	.comment__reply {
		font-size: 12px;
		line-height: 1.2em;
		padding-top: 1em;
	}
		.comment__reply-link {
			color: $link-color;

			&:hover {
				color: $link-color-hover;
			}
		}
}
