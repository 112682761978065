.team-result {

	.team-result__date,
	.team-result__competition {
		color: $headings-color;
	}

	.team-result__mvp,
	.team-result__competition {
		text-align: start;
	}

	.team-result__competition {
		padding-inline: $card-horizontal-padding;
	}

	tbody > tr > td {

		&.team-result__outcome {
			color: $headings-color;
		}

		&.team-result__sf,
		&.team-result__sa,
		&.team-result__fw {
			font-weight: normal;
		}
	}

	.team-result__recap {
		> a {
			display: inline-block;
			padding-block: .2em;
			padding-inline: .5em;
			color: $color-gray;

			&:hover {
				color: $headings-color;
			}
		}
	}

	.team-result__stats {


		> a {
			display: inline-block;
			padding-block: .2em;
			padding-inline: .5em;

			.alc-icon--chart {
				--icon-color: #{$color-gray};
			}

			&:hover {
				.alc-icon--chart {
					--icon-color: #{$headings-color};
				}
			}
		}

	}
}

// Team Results (team-score-team layout)
.team-result__teams-wrapper {

	.team-result__teams-score {
		margin: 0;
		color: $headings-color;
		font-weight: 700;

		.team-result__score {

			&.team-result__score--win {

				&::before {
					display: none;
				}
			}
		}
	}
}
