
.df-color-pallete__item {

	&.color-dark {
		background-color: $color-dark;
	}
	&.color-dark-lighten {
		background-color: $color-dark-lighten;
	}
	&.color-gray-2 {
		background-color: $color-gray-2;
		color: #37353c;
	}
	&.color-gray-3 {
		background-color: $color-gray-3;
		color: #83808c;
	}
}
