/* Checkout Review Order */
.df-checkout-review-order {

}
	.df-checkout-review-order-table {


		// Table Footer
		tfoot {

			tr {

				th {
					text-transform: none;
					color: $body-font-color;
					font-weight: 400;
				}

				th,
				td {
					font-size: 12px;
				}

				&.order-total {

					th {
						color: $headings-color;
						font-weight: bold;
					}

					.amount {
						color: $color-warning;
					}
				}

				&:first-child {

					th,
					td {
						padding-top: 24px;
						border-top: none;
					}
				}
			}
		}

		.cart-subtotal {

			@if $template == 'football' {
				color: $headings-color;
			}
		}
		.shipping {

		}
		.coupon {

		}
		.order-total {

		}
	}
