/*
// #Album
// --------------------------------------------------
*/

// Album Space lg
.album-wrapper {

	@media (min-width: 992px) {
		margin-left: -15px;
		margin-right: -15px;
	}
}

	.album__item {

		@media (min-width: 992px) {
			padding-left: 15px;
			padding-right: 15px;
			margin-bottom: 30px;
		}
	}

		.album__item-title {
			font-size: $base-font-size;
			text-transform: none;
			margin-bottom: .35em;

			@media (min-width: 992px) {
				font-size: $base-font-size;
			}
		}

		.album__item-date {
			text-transform: none;
			font-weight: normal;
		}
