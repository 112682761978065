/* Widget: Team History Table */
.alc-table-stats__team-history {

	> tbody > tr > td {

		&.alc-table-stats__cell-g,
		&.alc-table-stats__cell-p,
		&.alc-table-stats__cell-otl {
			font-weight: normal;
			color: $body-font-color;
		}
	}
}
