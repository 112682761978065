/* Widget: Future Events */
.alc-widget-events-future {

}

	.alc-events-future {

	}
		// Primary Event
		.alc-events-future__primary {

			& + * {
				border-block-start: 1px solid $card-border-color;
			}
		}
		.alc-primary-event {
			--font-size: #{$font-size-h6};
			--logo-size: 60px;
		}
			.alc-primary-event__heading {

			}
			.alc-event-heading {
				display: flex;
				justify-content: space-between;
				background-color: $card-subheader-bg;
				border-block-end: 1px solid $card-border-color;
				padding-inline: $card-horizontal-padding;
				padding-block: 1.5em;
				font-size: var(--font-size);
				line-height: 1em;
			}
				.alc-event-heading__league {
					margin: 0;
					font-size: var(--font-size);
					line-height: 1em;
				}
				.alc-event-heading__date {
					margin: 0;
					font-size: var(--font-size);
					line-height: 1em;
					text-transform: uppercase;
					font-weight: bold;
				}

			.alc-primary-event__details {
				padding-block: 30px 36px;
				padding-inline: $card-horizontal-padding;
				display: flex;
				flex-wrap: nowrap;
				text-align: center;

				& + * {
					border-block-start: 1px solid $card-border-color;
				}
			}
				// Team
				.alc-primary-event-team {
					order: 0;

					& + & {
						order: 2;
					}
				}
					.alc-primary-event-team__logo {
						max-height: var(--logo-size);
						margin-bottom: 15px;

						img {
							height: 100%;
							width: auto;
						}
					}
					.alc-primary-event-team__name {
						font-size: $font-size-h5;
						line-height: 1em;
						margin-bottom: .5em;
					}
					.alc-primary-event-team__meta {
						font-size: 9px;
						line-height: 1em;
					}
					.alc-primary-event-team__form-events {
						padding-top: 18px;
					}

				// Meta (versus)
				.alc-primary-event-meta {
					order: 1;
					margin-inline: auto;
					font-size: $font-size-h6;
					line-height: 1em;
					text-transform: uppercase;
					font-weight: bold;
				}
					.alc-primary-event-meta__divider {
						font-family: $headings-font-family;
						font-size: $font-size-h2;
						line-height: var(--logo-size);
						color: $headings-color;
						font-weight: bold;
						margin-block-end: 15px;
					}
					.alc-primary-event-meta__time {
						display: block;
						color: $headings-color;
						margin-block-end: .5em;
					}
					.alc-primary-event-meta__status {
						color: $color-gray;
					}
					.alc-primary-event-meta__tickets {
						margin-block-start: 2em;

						> a {
							color: $headings-color;

							&:hover {
								color: $link-color-hover;
							}
						}
					}

			// Countdown
			.alc-primary-event__countdown {
				padding-block: 30px;
				padding-inline: 40px;
			}
				.alc-countdown-plain {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
				}
					.alc-countdown-plain__item {
						font-size: 11px;
						line-height: 1.2em;
						font-weight: bold;
						color: $headings-color;
						margin-inline: 0.15em;

						&::after {
							content: ":";
							display: inline-block;
							color: currentColor;
							margin-inline-start: .3em;
						}

						&:last-child {

							&::after {
								display: none;
							}
						}
					}
					.alc-countdown-plain__item--title {
						color: $color-gray;

						&::after {
							display: none;
						}
					}
					.alc-countdown-plain__item--days {

					}
					.alc-countdown-plain__item--hours {

					}
					.alc-countdown-plain__item--mins {

					}
					.alc-countdown-plain__item--secs {

						&::after {
							display: none;
						}
					}
						.alc-countdown-plain__item-label {
							text-transform: capitalize;
						}

					.alc-countdown-plain__progress {
						flex-basis: 100%;
						padding-block-start: 12px;
					}

		// Secondary Events
		.alc-events-future__secondary {

			.team-schedule {
				margin-block-end: 0;

				> tbody > tr > td {

					&.team-schedule__time {
						font-weight: normal;
						color: $body-font-color;
					}
				}
			}
		}
		.alc-secondary-events {

		}
