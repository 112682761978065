/*=============================================
=            Hockey Posts            =
=============================================*/

// Category Labels
.posts__cat-label {
	padding-left: 1.5em;
	padding-right: 1.5em;
	clip-path: polygon(8px 0, 100% 0%, calc(100% - 8px) 100%, 0% 100%);
}

// Post Card
.posts__item--card {

	&:not(.posts__item--card-grid) .posts__inner {
		padding-top: $card-vertical-padding;
	}

	.posts__cat-label {
		padding-top: .7em;
		padding-bottom: .6em;
	}
}

// Post Tile
.posts--tile {

	.posts__cat-label {
		padding-top: .7em;
		padding-bottom: .6em;
	}
}

.posts--tile-alt {

	.posts__thumb {

		&::after {
			background-image: none;
			background-color: rgba(0,0,0,1);
			opacity: .8;
			top: 0;
		}
	}

	.post__meta {
		color: $color-gray;
	}

	.posts__title {

		> a {
			color: $color-white;
		}
	}
}

// Single Post
.post--single {

	.posts__cat-label {
		padding-top: .7em;
		padding-bottom: .6em;
	}
}

/*=====  End of Hockey Posts  ======*/
