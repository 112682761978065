// Page Heading without Pattern
.page-heading--simple {
	height: 260px;
	padding: 0;

	@media (min-width: 768px) {
		height: 340px;
	}

	@media (min-width: 992px) {
		height: 390px;
	}

	@media (min-width: 1200px) {
		height: 460px;
		padding: 0;
	}

	@media (min-width: 1680px) {
		height: 540px;
	}

	@media (min-width: 1920px) {
		height: 580px;
	}
}
