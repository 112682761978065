/* Widget: Author Info */
.author-info {

}
	.author-info__img {
		margin-bottom: 18px;
	}
	.author-info__name {
		font-size: $base-font-size;
		line-height: 1.2em;
	}
	.author-info__desc {
		font-size: $font-size-sm;
		line-height: 1.67em;

		& + .author-info__footer {
			margin-top: 26px;
		}
	}
	.author-info__footer {

	}
		.author-info__social-links {

		}
