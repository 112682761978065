/* Widget: Lists */
.widget_archive,
.widget_nav_menu,
.widget_meta,
.widget_pages,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_rss,
.widget_product_categories,
.widget_layered_nav,
.widget_layered_nav_filter {


}

.widget--sidebar {

	.widget__list {
		text-transform: none;
		font-size: 12px;

		ul {
			font-size: 12px;
			background-color: $color-gray-3;

			li {

				a {
					color: $color-gray;

					&:hover {
						color: $color-2;
					}
				}
			}
		}
	}
}

.widget--footer {

	.widget__list {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		text-transform: none;
		line-height: 1.5em;

		li {
			padding-bottom: 13px;
		}

		> li {

			& + li {
				border-top: none;
			}

			> a {
				display: inline;
				padding: 0;
				color: $body-font-color;

				&:hover {
					color: $link-color-hover;
				}
			}

			&.has-children {
				background-image: $select-caret;
				background-position: right $card-horizontal-padding top $card-vertical-padding;
				background-repeat: no-repeat;
				background-size: 6px 4px;
			}
		}
	}
}
