/* Forms
/* ------------------------- */

.form-group--password-forgot {
	display: flex;
	padding: 6px 0;

	label {
		font-size: 12px;
	}

	.password-reminder {
		font-size: 12px;
		text-transform: none;
		color: $color-gray;
	}
}
