/* Alerts
/* ------------------------- */

.alert {
	padding-left: 50px + $alert-icon-width;

	&::before {
		background-size: 15px 15px;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		left: $alert-icon-width;
		top: 0;
		bottom: 0;
		width: 24px;
		clip-path: polygon(100% 0, 0 0, 0 100%);
	}
}

// Dismissible alerts
.alert-dismissible {

	// Adjust close link position
	.close {
		color: $headings-color;
		opacity: 1;
	}
}


// Alternate styles

.alert-success {

	&::before {
		background-size: 18px 14px;
	}

	&::after {
		background-color: $alert-success-icon-bg;
	}
}

.alert-info {

	&::after {
		background-color: $alert-info-icon-bg;
	}
}

.alert-warning {

	&::after {
		background-color: $alert-warning-icon-bg;
	}
}

.alert-danger {

	&::before {
		background-size: 14px 14px;
	}

	&::after {
		background-color: $alert-danger-icon-bg;
	}
}
