// Categories
.newslog__item {
	font-size: $font-size-sm;
	line-height: 1.67em;
	padding-top: $card-vertical-padding * 0.75;
	padding-bottom: $card-vertical-padding * 0.75;

	strong {
		font-weight: 700;
	}

	.newslog__item-inner {

		&::before {
			top: 4px;
			font-family: "Font Awesome 5 Free";
			font-weight: 700;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 10px;
			width: 18px;
			height: 18px;
			line-height: 18px;
			padding: 0;
			text-align: center;
			background-image: none;
			background-color: $color-primary;
			color: $color-white;
		}
	}
}
.newslog__item--injury {

	.newslog__item-inner::before {
		content: "\f067";
		background-color: $color-success;
	}
}
.newslog__item--join {

	.newslog__item-inner::before {
		content: "\f054";
		background-color: $color-primary;
	}
}
.newslog__item--exit {

	.newslog__item-inner::before {
		content: "\f053";
		background-color: $color-danger;
	}
}

.newslog__item--award {

	.newslog__item-inner::before {
		content: "\f005";
		background-color: #ffbd0c;
	}
}
	.newslog__date {
		font-size: 10px;
		color: $color-gray;
	}
