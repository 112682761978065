/* Widget: Products */
.widget-products {

}

	.products-list {
		padding-block: 10px;

		.card & {

		}
	}
		.products-list__item {
			padding: 10px $card-horizontal-padding;

			& + & {
				border-top: none;
			}
		}
			.products-list__inner {
				padding-block-start: 1px;
			}
				.products-list__product-thumb {
					margin-inline-end: 16px;
				}
				.products-list__product-cat {
					font-size: 9px;
					font-weight: 400;
					margin-bottom: .2em;
					color: $color-gray;
				}
				.products-list__product-name {
					margin-bottom: .4em;
					text-transform: none;
				}
				.products-list__product-ratings {
					font-size: 7px;
					line-height: 1em;
					margin-block-end: 4px;
				}
				.products-list__product-sum {
					font-size: 10px;
					color: $color-4-darken;
				}
					.products-list__product-price {

					}
					.products-list__product-count {

					}
